
import {
  Component,
  Vue,
  Watch
} from 'vue-property-decorator'
import CompanyService from '../services/company-service'
import DeliveryService from '../services/delivery-service'
import ActivityService from '../services/activity-service'
import LocationService from '../services/location-service'
import DateHelper from '@/mixins/date-mixins'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import {
  NotifyModule
} from '@/store/modules/NotifyModule'
import {
  Printd
} from 'printd'
import {
  BreadcrumbModule
} from '@/store/modules/BreadcrumbModule'
import Status from '@/components/Status/index.vue'
  @Component({
    name: 'DispatchSLPA',
    components: {
      Status
    }
  })
export default class DispatchSLPA extends Vue {
    protected isLoader = false
    protected isTableBusy = false
    protected isResult = false
    protected bowsers: any = []
    protected note: any = {}
    protected seals: any = []
    protected compartments: any = ['O', 'W', 'M']
    protected locations: any = []
    protected location: any = {}
    protected companies: any = []
    protected company: any = {}
    protected document: any = null
    protected companyAddress: any = null

    protected fields: any = [{
      key: 'vehicle_number',
      label: 'Vehicle No.',
      thStyle: {
        width: '6%'
      }
    },
    {
      key: 'status',
      label: 'Status',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'vehicle_type',
      label: 'Type',
      thStyle: {
        width: '6%'
      }
    },
    {
      key: 'vehicle_capacity',
      label: 'Capacity',
      thStyle: {
        width: '6%'
      }
    },
    {
      key: 'seal_up',
      label: 'Seal Up',
      thStyle: {
        width: '15%'
      }
    },
    {
      key: 'seal_down',
      label: 'Seal Down',
      thStyle: {
        width: '15%'
      }
    }
    ]

    protected styles = ['https://api.smc-spl-application.com/print.css']

    created () {
      this.boot()
    }

    @Watch('note', {
      deep: true
    })
    setSeals () {
      if (this.note.seals.length !== 0) {
        this.seals = this.note.seals
      }
    }

    protected boot () {
      BreadcrumbModule.setBreadcrumb(this.$route)
      this.populateBowsers()
      this.document = new Printd()
    }

    public openDeliveryModal (bowser: any) {
      this.populateCompanies()
      this.populateYards()
      this.populateNoteDetails(bowser);
      (this.$refs.deliveryModal as any).show()
    }

    public close () {
      this.clear()
      this.reset(this.note);
      (this.$refs.deliveryModal as any).hide()
    }

    protected async populateCompanies () {
      this.companies = []
      this.company = {}
      this.companyAddress = null
      const response = await CompanyService.getCompanies()
      response.data.forEach((company) => {
        this.companies.push({
          value: {
            id: company.id,
            name: company.name
          },
          text: company.name
        })
      })
    }

    protected async populateCompanyAddress (companyId: any) {
      const response = await CompanyService.getCompanyAddress(companyId)
      this.companyAddress = response.data.address
    }

    protected populateBowsers () {
      this.isTableBusy = true
      DeliveryService.getApprovedDispatchedBowsers().then((response) => {
        this.isTableBusy = false
        let bowsers: any = []
        bowsers = response.data
        const statusOrder = ['approved', 'dispatched']
        this.bowsers = bowsers.sort((a, b) => statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status))
      })
    }

    protected populateYards () {
      this.locations = []
      LocationService.getYards().then((response) => {
        response.data.forEach(item => {
          this.locations.push({
            value: {
              id: item.id,
              name: item.name
            },
            text: item.name
          })
        })
      })
    }

    protected populateNoteDetails (note) {
      this.reset(this.note)
      this.isLoader = true
      this.isResult = false
      DeliveryService.getNoteDetails(note.id).then((response) => {
        this.isResult = true
        this.isLoader = false

        const noteData = response.data
        this.note = {
          ...noteData,
          job_numbers: noteData.lines.map(line => line.job_number).join(', '),
          delivery_date: DateHelper.fullDateTime(new Date()),
          est_arrival_date: DateHelper.fullDateTime(new Date(new Date().getTime() + (4 * 60 * 60 *
            1000))),
          dispatch_date: DateHelper.twoDigitYearMonthDate(new Date(response.data.dispatch_date)),
          seals: []
        }

        if (note.status === 'dispatched') {
          for (let i = 0; i < 6; i++) {
            this.note.seals.push({
              compartment: this.note.compartment[i],
              seal_up: this.note.seal_up[i],
              seal_down: this.note.seal_down[i]
            })
          }
          this.location = {
            id: this.note.location_id,
            name: this.note.location_name
          }
        } else {
          for (let i = 0; i < 6; i++) {
            this.note.seals.push({
              compartment: null,
              seal_up: null,
              seal_down: null
            })
          }
        }
      })
    }

    protected saveAndPrint () {
      this.save()
      this.printNote()
    }

    protected printNote () {
      this.$nextTick(() => {
        this.document.print(document.getElementById('deliveryNote'), this.styles)
      })
      this.clear()
    }

    protected save () {
      if (this.note.seals.filter(seal => seal.compartment !== null).length < 1) {
        return NotifyModule.set('Seals are empty. Please check..')
      }
      const compartments: any = []
      const sealUp: any = []
      const sealDown: any = []
      this.note.seals.forEach(seal => {
        compartments.push(seal.compartment)
        sealUp.push(seal.seal_up)
        sealDown.push(seal.seal_down)
      })

      const note = {
        id: this.note.id,
        dispatch_id: this.note.dispatch_id,
        location_id: this.location.id,
        location_name: this.location.name,
        compartment: compartments.toString(),
        seal_up: sealUp.toString(),
        seal_down: sealDown.toString(),
        status: 'dispatched',
        modified_user: AuthModule.user.fullname
      }
      ActivityService.update(note).then((response) => {
        ToastModule.message(response.data.message)
        this.close()
        this.populateBowsers()
      }).catch(error => {
        ToastModule.message(error.response.data.message)
      })
    }

    protected clear () {
      this.location = {}
      this.company = {}
      this.companyAddress = null
      this.seals = []
      this.isLoader = false
      this.isResult = false
    }

    protected reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

