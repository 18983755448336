import APIService from '@/services/api-service'

class CompanyService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getCompanies () {
    return this.connector.get('/company/names')
  }

  public getCompanyAddress (sourceId: any) {
    return this.connector.get(`company/${sourceId}/address/Primary`)
  }
}

export default new CompanyService()
